import request from '../request'

const baseURL = 'crm'

//线索列表
function clueInfoList(data) {
    return request({
        method: "json",
        url: `/${baseURL}/clueInfo/list`,
        data
    })
}

// 客户列表
function customerList(data) {
    return request({
        method: "json",
        url: `/${baseURL}/customer/list`,
        data
    })
}

//商机列表
function businessInfoList(data) {
    return request({
        method: "json",
        url: `/${baseURL}/businessInfo/list`,
        data
    })
}

//保存线索
function clueInfoSave(data) {
    return request({
        method: "json",
        url: `/${baseURL}/clueInfo/save`,
        data
    })
}


//保存商机
function businessInfoSave(data) {
    return request({
        method: "json",
        url: `/${baseURL}/businessInfo/save`,
        data
    })
}

//保存跟进
function saveFollowup(data) {
    return request({
        method: "json",
        url: `/${baseURL}/activity/saveFollowup`,
        data
    })
}

//改变线索阶段状态
function clueInfoChangeState(data) {
    return request({
        method: "json",
        url: `/${baseURL}/clueInfo/changeState?id=${data.id}&state=${data.state}`,
    })
}

//改变商机阶段状态
function businessInfoChangeState(data) {
    return request({
        method: "post",
        url: `/${baseURL}/businessInfo/changeState?id=${data.id}&bizState=${data.state}`,
    })
}


//获取线索阶段状态
function clueStateList(data) {
    return request({
        method: "get",
        url: `/${baseURL}/clueState/list?type=${data}`
    })
}

//获取线索阶段状态
function clueStateList1(data) {
    return request({
        method: "get",
        url: `/${baseURL}/clueState/listAllStage?type=${data}`
    })
}

//获取商机阶段状态
function businessStateList(data) {
    return request({
        method: "get",
        url: `/${baseURL}/businessState/list?type=${data}`
    })
}

//线索详情
function clueInfoDetail(data) {
    if (data) {
        return request({
            method: "get",
            url: `/${baseURL}/clueInfo/detail?id=${data}`
        })
    } else {
        return request({
            method: "get",
            url: `/${baseURL}/clueInfo/detail`
        })
    }

}

// 客户详情
function customerDetail(customerId) {
    return request({
        method: "post",
        url: `/${baseURL}/customer/detail?customerId=${customerId}`,
    })
}

// 获取通话记录列表 /crm/callRecord/fromCustomerIdFindAllCallRecord
function fromCustomerIdFindAllCallRecord(customerId) {
    return request({
        method: "post",
        url: `/${baseURL}/callRecord/fromCustomerIdFindAllCallRecord?customerId=${customerId}`,
    })
}

/**
 * getCallRecordMediaUrlByCallRecordId 根据通话记录id获取通话录音
 * @param callRecordId 通话记录id
 * @returns {*} 通话录音url
 */
function getCallRecordMediaUrlByCallRecordId(callRecordId) {
    return request({
        method: "post",
        url: `/${baseURL}/callRecord/getCallRecordMediaUrlByCallRecordId?callRecordId=${callRecordId}`,
    })
}

/**
 * fromSourceCallIdFindCallText 根据通话记录id获取通话记录文本
 * @param sourceCallId 通话记录id
 * @returns {*} 通话记录文本
 */
function fromSourceCallIdFindCallText(sourceCallId) {
    return request({
        method: "post",
        url: `/${baseURL}/callRecord/fromSourceCallIdFindCallText?sourceCallId=${sourceCallId}`,
    })
}


//商机详情
function businessInfoDetail(data, type) {
    var url = `/${baseURL}/businessInfo/detail?id=${data}`;
    if (type == "careate") {
        url = `/${baseURL}/businessInfo/detail?clueId=${data}`;
    }
    return request({
        method: "get",
        url: url
    })
}

//阶段列表
function getFollowList(data) {
    return request({
        method: "json",
        url: `/${baseURL}/activity/getFollowList`,
        data
    })
}

//设置标签
function clueInfoSetTags(data) {
    return request({
        method: "json",
        url: `/${baseURL}/clueInfo/setTags`,
        data
    })
}

//设置线索是否有效
function clueInfoSetActive(data) {
    return request({
        method: "post",
        url: `/${baseURL}/clueInfo/setActive`,
        data
    })
}

//设置商机是否有效
function businessInfoChangeActive(data) {
    return request({
        method: "post",
        url: `/${baseURL}/businessInfo/changeActive`,
        data
    })
}


//转移线索
function clueInfoChangeOwner(data) {
    return request({
        method: "post",
        url: `/${baseURL}/clueInfo/changeOwner`,
        data
    })
}

//转移商机
function businessInfoChangeOwner(data) {
    return request({
        method: "post",
        url: `/${baseURL}/businessInfo/changeOwner`,
        data
    })
}

// 转移客户
function customerChangeOwner(data) {
    return request({
        method: "post",
        url: `/${baseURL}/customer/changeOwner`,
        data
    })
}

//线索打电话
function businessInfoPhone(data) {
    return request({
        method: "post",
        url: `/${baseURL}/businessInfo/phone`,
        data
    })
}

//商机打电话
function clueInfoPhone(data) {
    return request({
        method: "post",
        url: `/${baseURL}/clueInfo/phone`,
        data
    })
}

// 客户打电话 callPhoneRecord
function customerCallPhoneRecord(data) {
    return request({
        method: "post",
        url: `/${baseURL}/customer/callPhoneRecord`,
        data
    })
}

//查询操作记录
function getOperateList(data) {
    return request({
        method: "json",
        url: `/${baseURL}/activity/getOperateList`,
        data
    })
}

//保存赔付信息
function clueRefundSave(data) {
    return request({
        method: "json",
        url: `/${baseURL}/clueRefund/save`,
        data
    })
}

//获取赔付信息
function clueRefundDetaildata(data) {
    return request({
        method: "get",
        url: `/${baseURL}/clueRefund/detail?clueId=${data}`
    })
}

//获取跟进记录结构化字段信息
function getFollowRecordExtraInfoField(data) {
    return request({
        method: "get",
        url: `/${baseURL}/followRecordExtraInfo/getFollowRecordExtraInfoField`
    })
}

export {
    clueRefundSave,
    clueRefundDetaildata,
    businessInfoPhone,
    clueInfoPhone,
    customerCallPhoneRecord,
    clueStateList,
    clueStateList1,
    clueInfoList,
    customerList,
    clueInfoSave,
    saveFollowup,
    clueInfoDetail,
    customerDetail,
    clueInfoChangeState,
    getFollowList,
    clueInfoSetTags,
    clueInfoSetActive,
    clueInfoChangeOwner,
    getOperateList,
    businessInfoSave,
    businessInfoDetail,
    getCallRecordMediaUrlByCallRecordId,
    fromCustomerIdFindAllCallRecord,
    fromSourceCallIdFindCallText,
    businessInfoList,
    businessStateList,
    businessInfoChangeActive,
    businessInfoChangeState,
    businessInfoChangeOwner,
    customerChangeOwner,
    getFollowRecordExtraInfoField
}
