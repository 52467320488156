<template>
    <div class="searchHeader">
        <form action="/">
            <van-search
                    v-model="queryText"
                    show-action
                    shape="round"
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                    @cancel="onCancel"
            >
                <template #left-icon>
                    <div class="iconfont searchIcon">&#xe628;</div>
                </template>
            </van-search>
        </form>
    </div>
</template>

<script>
import { Toast } from 'vant';
export default {
    name: "SearchHeader",
    props:["query"],
    data() {
        return {
            queryText: this.query
        };
    },
    methods: {
        onSearch(val) {
            this.$emit("submitSearch",this.queryText,true)
        },
        onCancel() {
            this.$emit("cancel")
        },
    },
}
</script>

<style scoped>

</style>
