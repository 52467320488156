<template>
    <van-field
            v-model="item.value"
            :placeholder="item.placeholder"
            :label="item.label"
            class="hide"
            :disabled="item.disabled"
            :required="item.disabled ? false : item.isNull">
    </van-field>
</template>

<script>
    import QaBase from "./QaBase";

    export default {
        extends: QaBase,
        name: "QaHidden"
    }
</script>

