<template>
    <div :class="'van-after-border '+item.class">
        <van-field readonly clickable :name="item.key" :value="item.value.address || ''" placeholder="点击选择地区"
            @click="_click" is-link>
            <template #label>
                <span class="custom-title">{{label}}
                    <van-icon v-if="help" @click="showHelp" name="question" />
                </span>
            </template>
        </van-field>
        <div>
            <van-popup position="bottom" v-model="showPicker">
                <van-area :area-list="areaList" @cancel='showPicker = false' @confirm="onConfirm" />
            </van-popup>
        </div>

    </div>
</template>
<script>
    import QaBase from "./QaBase";
    import {
        areaList
    } from '@vant/area-data';
    export default {
        extends: QaBase,
        data() {
            return {
                showPicker: false,
                areaList
            }
        },
        props: ["item"],
        methods: {
            _click() {
                this.showPicker = true;
            },
            onConfirm(val) {
                this.item.value = {
                    address: val.map(item => item.name).join(',')
                }
                this.showPicker = false;
            }
        }
    }
</script>