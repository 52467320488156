<script type="text/jsx">
import QaText from "./form-el/QaText";
import QaSelect from "./form-el/QaSelect";
import QaDate from "./form-el/QaDate";
import QaTime from "./form-el/QaTime";
import QaDateTime from "./form-el/QaDateTime";
import QaCheckBox from "./form-el/QaCheckBox";
import QaChoosePerson from "./form-el/QaChoosePerson";
import QaHidden from "./form-el/QaHidden";
import QaTextArea from "./form-el/QaTextArea";
import QaUser from "./form-el/QaUser";
import QaArea from "./form-el/QaArea";
import QaTagText from "./form-el/QaTagText";

export default {
    name: "DynamicFormPartItem",
    props: {
        item: {
            required: true
        },
        items:{}
    },
    components: {
        QaText, QaSelect, QaDate, QaTime, QaDateTime,
        QaCheckBox, QaChoosePerson, QaHidden, QaTextArea, QaUser, QaArea,
        QaTagText
    },
    data() {
        return {}
    },
    methods: {},
    render() {
        let item = this.$props.item;
        let items = this.$props.items;
        if(item.hidden){
            return false;
        }
        //如果是input输入框
        if (item.formType == 'text') {
            return (<qa-text item={item}></qa-text>)
        } else if (item.formType.includes('tag_')) {
            return (<qa-tag-text item={item}></qa-tag-text>)
        } else if (item.formType == 'select') {
            return (<qa-select item={item}></qa-select>)
        } else if (item.formType == 'checkbox') {
            return (<qa-check-box item={item}></qa-check-box>)
        } else if (item.formType == 'date') {
            return (<qa-date item={item}></qa-date>)
        } else if (item.formType == 'time') {
            return (<qa-time item={item}></qa-time>)
        } else if (item.formType == 'datetime') {
            return (<qa-date item={item}></qa-date>)
        } else if (item.formType == 'person') {
            return (<qa-choose-person item={item}></qa-choose-person>)
        } else if (item.formType == 'hidden') {
            return (<qa-hidden item={item}></qa-hidden>)
        } else if (item.formType == 'textarea') {
            return (<qa-text-area item={item}></qa-text-area>)
        } else if (item.formType == 'mobile') {
            return (<qa-text item={item}></qa-text>)
        } else if (item.formType == 'user') {
            return (<qa-user item={item}  items={items}></qa-user>)
        } else if (item.formType == 'floatnumber') {
            return (<qa-text item={item}></qa-text>)
        }else if (item.formType == 'number') {
            return (<qa-area item={item}></qa-area>)
        } else if (item.formType == 'map_address') {
            return (<qa-area item={item} items={items}></qa-area>)
        } else {
            return (<div>未识别的组件类型：【{item.formType}】!</div>)
        }

    }
}
</script>
