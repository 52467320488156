<template>
    <div class="distanceHeader">
        <dynamic-form-part v-for="o of formEl"  v-bind:key="o.category" :hidden="o.hidden" :category="o.category" :items="o.items"></dynamic-form-part>
    </div>
</template>

<script>
    import DynamicFormPart from "./DynamicFormPart";

    export default {
        props: {
            formEl: {
                required: true
            }
        },
        components: {DynamicFormPart}
    }
</script>



