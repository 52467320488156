<template>
    <div class="centerTitle">{{processTitle}}</div>
</template>

<script>
export default {
    name: "CenterTitle",
    props: {
        processTitle: String
    }
}
</script>

<style scoped>

</style>
