<template>
    <div :class="'van-after-border '+ item.class">
        <van-cell
                clickable
                title-class="formCellTitle"
                value-class="formCellValue"
                :readonly="item.readonly?true:false"
                :name="item.key"
                :value="this.choosePerson.length> 0 ? '' : '点击选择人员'"
                :placeholder="item.placeholder?item.placeholder:'点击选择人员'"
                @click="listPerson"
        >
            <template #title>
                <span class="custom-title">{{label}}<van-icon v-if="help" @click="showHelp"  name="question" /></span>
            </template>
            <van-tag round color="#87ccf2" v-for="item of choosePerson" :key="item.key">{{item.sname}}</van-tag>
            <template #right-icon>
                <div class="iconfont rightIcon">&#xe613;</div>
            </template>
        </van-cell>
        <van-popup v-model="showPicker" position="bottom" :style="{ height: '100%' }">
            <search-header @cancel="cancel" @submitSearch="doSearch"></search-header>
            <person-list @load="pageLoad" :choosePerson="choosePerson" :list="this.list" :limit="limit" :loading="loading" :finished="finished"
                         :btnText="btnText" @submitSelected="submitSelected"></person-list>
        </van-popup>
    </div>
</template>

<script>
    import QaBase from "./QaBase";
    import SearchHeader from "../../common/SearchHeader";
    import PersonList from "../../common/PersonList";
    import request from '@/utils/request'

    export default {
        name: "ChoosePerson",
        components: {PersonList, SearchHeader},
        extends: QaBase,
        data() {
            return {
                limit:0,
                loading: false,
                finished: false,
                pageNO: 1,
                pageSize: 20,
                query: "",
                btnText: '确定',
                refProp: this.$props.item,
                showPicker: false,
                choosePerson: [],
                list: []

            }
        },
        mounted() {
           // this.list = this.loadPersonList();
        },
        props: ["item"],
        methods: {
            listPerson(){
                if(this.disabled||this.readonly){
                    this.showReadonlyTip();
                    return;
                }
                this.showPicker=true
                this.finished = false;
                this.pageNO = 0;
                this.pageSize = 20;
                this.loading = true;
                this.query = "";
                this.list.length=0;
                this.loadPersonList();
            },
            cancel(){
                this.showPicker = false;
            },
            doSearch(text) {
                this.finished = false;
                this.pageNO = 0;
                this.pageSize = 20;
                this.loading = true;
                this.query = text;
                this.list.length=0;
                this.loadPersonList();
            },
            pageLoad(){
                this.pageNO++;
                this.loadPersonList();
            },
            loadPersonList() {
                let $this = this;
                let url = this.URLS.API_BASE_URL + "/sys/persons";
                let options = this.$props.item.options;
                let params = {
                    roleId:(options&&options.roleId) ? options.roleId.roleId : -1,
                    pageNum: this.pageNO,
                    pageSize: this.pageSize,
                    query: this.query
                };
                request({url: url, method: "post", params: params}).then(response => {
                    if (response.retCode == '000000') {
                        response.data.data.forEach((val, key) => {
                            let obj = {};
                            obj.loginname = val.LOGINNAME;
                            obj.sname = val.USERNAME;
                            obj.department = val.DEPARTMENTNAME;
                            obj.sid = val.USERID;
                            if($this.isChecked(obj)){
                                obj.checked=true;
                            }
                            $this.list.push(obj);
                        })
                        $this.loading = false;
                        if (!response.data.data || response.data.data.length < this.pageSize) {
                            this.finished = true;
                        }
                    }
                }).catch(err => {
                    $this.loading = false;
                    $this.finished = true;
                })


            },
            isChecked(obj){
                for(let val of this.choosePerson){
                    if(val.sid==obj.sid){
                        return true;
                    }
                }
                return false;
            },
            submitSelected(selectedPerson) {
                this.showPicker = false;
                this.choosePerson = selectedPerson;
                this.$props.item.value = this.choosePerson;
            }
        }
    }
</script>

<style scoped>
    .searchHeader {
        height: 51px;
        position: initial;
        border-bottom: 1px solid #ebedf0;
    }

    .personList {
        height: calc(100% - 51px);
    }

    .van-tag:not(:last-child) {
        margin-right: 4px;
    }
</style>
