<template>
    <div :class="'van-after-border '+item.class">
        <van-field
                readonly
                clickable
                :name="item.key"
                v-model="item.value"
                :placeholder="item.placeholder||'点击选择日期'"
                @click="_click"
        >
            <template #label>
                <span class="custom-title">{{label}}<van-icon v-if="help" @click="showHelp"  name="question" /></span>
            </template>
            <template #right-icon>
            </template>
        </van-field>
        <van-popup position="bottom" v-model="showPicker">
            <van-datetime-picker
                    type="datetime"
                    @cancel="onCancel"
                    @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>
<script>
    import QaBase from "./QaBase";
    import {parseTime} from "@/utils";

    export default {
        name: "QaDateTime",
        extends: QaBase,
        data() {
            return {
                refProp: this.$props.item,
                showPicker: false
            }
        },
        props: ["item"],
        computed: {
            columns: function () {
                let columns = this.initValue
                return columns.map(function (option) {
                    return option.label
                })

            }
        }, methods: {
            _click(){
                if(this.disabled||this.readonly){
                    this.showReadonlyTip();
                    return;
                }
                this.showPicker=true
            },
            onConfirm(text) {
                this.showPicker = false;
                this.$props.item.value = parseTime(text)
                this.$props.item.showVal = parseTime(text)
            },
            onCancel() {
                this.showPicker = false;
            }
        }
    }
</script>
