<template>
    <div :class="hidden?'hide':''">
        <center-title v-if="category" :processTitle="category"></center-title>
        <dynamic-form-part-item v-for="item in items" :key="item.key" :item="item instanceof Array?item[1]:item" :items="items"></dynamic-form-part-item>
    </div>

</template>
<script>
    import DynamicFormPartItem from '../../components/d-form/DynamicFormPartItem'
    import CenterTitle from "../common/CenterTitle";

    export default {
        props: {
            hidden:Boolean,
            category: String,
            items: {
                required: true
            }
        },
        components: {CenterTitle, DynamicFormPartItem},

    }
</script>
<style>
.hide{
    display: none;
}</style>
