<template>
    <div>
        <van-field :title="item.name" :value="user.realname || user.name" @click="showPanel" :required="required"
            readonly="readonly" is-link :rules="[{ validator, message:'请选择'+item.name }]">
            <template #label>
                <span class="custom-title">{{item.name}}
                    <van-icon v-if="help" @click="showHelp" name="question" /></span>
            </template>
        </van-field>
        <div>
            <select-user :show='show' :selectedUser='selected' isRadio @close='show = false' @confirm="onConfirm" />
        </div>
    </div>
</template>

<script>
    import SelectUser from "@/components/SelectUser"
    import QaBase from "./QaBase";
    export default {
        extends: QaBase,
        components: {
            SelectUser
        },
        props: ['item', 'items'],
        data() {
            return {
                show: false
            };
        },
        computed: {
            user() {
                if (!this.item.value) return []
                const {
                    memberId,
                    realname,
                    name
                } = this.item.value[0]
                return {
                    memberId,
                    realname,
                    name
                }
            },
            selected() {
                return this.user.memberId ? [this.user.memberId] : []
            }
        },
        methods: {
            showPanel() {
                // for (let e of this.items) {
                //     if (e.fieldName == "showChangeOwner") {
                //         if (e.value) {
                //             this.show = true;
                //         } else {
                //             this.$toast.fail("当前用户权限无法转移线索！");
                //         }
                //         return;
                //     }
                // }
                this.show = true;
            },
            validator(val, rule) {
                if (this.required) {
                    if (val) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            },
            onConfirm(val) {

                if (val.length) {
                    const {
                        id,
                        name
                    } = val[0]
                    this.item.value = [{
                        memberId: id,
                        name
                    }]
                } else {
                    this.item.value = null
                }
            }
        },
    };
</script>

<style scoped lang="less">
</style>