<template>
    <van-cell :class="item.class" :title="item.label" title-class="formCellTitle">
        <template #title>
            <span  class="custom-title">{{label}}<van-icon v-if="help" @click="showHelp"  name="question" /></span>
        </template>
        <van-checkbox-group direction="horizontal" v-model="item.value">
            <van-checkbox v-for="option of initValue" :key="option.key"
                          :shape="item.shape || 'square'"
                          :icon="item.icon || ''"
                          :checkedColor="item.checkedColor || '#85B214 '"
                          :disabled="disabled||readonly"
                          :name="option.key"
            >
                {{option.label}}
            </van-checkbox>
        </van-checkbox-group>
    </van-cell>
</template>
<script>
    import QaBase from "./QaBase";
    import {toJson} from "../../../utils/d-form";

    export default {
        name: "QaCheckBox",
        extends:QaBase,
        data() {
            if(!this.$props.item.value){
                this.$props.item.value=[]
            }
            return {
                refProp: this.$props.item,
                showPicker: false
            }
        },
        beforeCreate(){

        },
        created(){

        },
        mounted(){

           // this.value= this.$props.item.value
        },
        props: ["item"],
        methods: {
            initData() {
                let initValue;
                if (this.$props.item.initValFeedType == 'request') {
                    //ajax请求获取数据？？？？
                    initValue = toJson(this.$props.item.initValFeedTypeText);
                } else if (this.$props.item.initValFeedType == 'function') {
                    initValue = this.$props.item.initValFeedTypeText;
                } else {
                    initValue = toJson(this.$props.item.initValFeedTypeText);
                    initValue.map(option => {
                        if(!option.key){
                            option.key = option.v
                        }
                        if(!option.label){
                            option.label = option.t
                        }

                    })
                }

                return initValue;
            },
            showData() {
                alert(JSON.stringify(this.initValue))
            }

        }


    }
</script>
