<template>
    <div></div>
</template>
<script>
    import {toJson} from "../../../utils/d-form";
    import {Toast} from 'vant'

    export default {
        name: "QaBase",
        props: ["item"],
        data() {
            return {
                initValue: this.initData(),
                value: this.$props.item.value,
                showVal: this.$props.item.showVal,
            }
        },
        computed: {
            label: function () {
                let name = this.$props.item.name;
                if (name.indexOf("(") > 0) {
                    name = name.substr(0, name.indexOf("("));
                } else if (name.indexOf("（") > 0) {
                    name = name.substr(0, name.indexOf("（"));
                }
                return name;
            },
            help: function () {
                let name = this.$props.item.name;
                if (name.indexOf("(") > 0) {
                    return true;
                } else if (name.indexOf("（") > 0) {
                    return true;
                }
                return false;
            },
            readonly: function () {
                //如果readonly有值但为 ’0',认为是false
                if (this.$props.item.readonly) {
                    if (this.$props.item.readonly == '0') {
                        return false
                    }
                } else {
                    return false
                }
                return true;
            },
            disabled: function () {
                if (this.$props.item.disabled) {
                    if (this.$props.item.disabled == '0') {
                        return false
                    }
                } else {
                    return false
                }
                return true;

            },
            required: function () {
                if (this.$props.item.isNull) {
                    if (this.$props.item.isNull == 0) {
                        return false
                    }
                } else {
                    return false
                }
                return true;
            }
        },
        beforeCreate() {

        },
        methods: {
            showHelp() {
                Toast({
                    message: this.$props.item.label
                });
            },
            showReadonlyTip() {
                Toast.fail("此为只读项，不可修改！")
            },
            initData() {
                let initValue;
                if (this.$props.item.initValFeedType == 'request') {
                    //ajax请求获取数据？？？？
                    initValue = toJson(this.$props.item.initValFeedTypeText);
                } else if (this.$props.item.initValFeedType == 'function') {
                    initValue = this.$props.item.initValFeedTypeText;
                } else {
                    initValue = toJson(this.$props.item.initValFeedTypeText);
                }

                return initValue;
            }
        }
    }
</script>
