/**
 * 将给定的array中的k、v键转化为这里执行的kv，key、label
 * @param item
 * @param kName
 * @param vName
 * @returns {[]}
 */
export function kvMap(item, kName, vName) {
    let arr = [];
    for (let o of item) {
        arr.push({key: o[kName], label: o[vName]});
    }
    return arr;
}


/**
 * 如果给的参数是字符串，会转化成json对象
 * @param str
 * @returns {any}
 */
export function toJson(str) {
    if (str&&typeof str === 'string') {
        try{
            return JSON.parse(str);
        }catch(err){
            return str;
        }
    }
    return str;
}
