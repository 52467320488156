<template>
    <div class="personList">
        <van-list class="personListGroup"  v-model="loading"
             :finished="finished"
             finished-text="没有更多了"
             @load="onLoad">
            <div class="personListItem" v-for="(item,index) of list" :key="item.id" @click="toggleCheck(index)">
                <div class="personItemPhoto">
                    <img class="personPhotoImg" :src="imgUrl">
                </div>
                <div class="personItemInfo">
                    <div class="personChineseName" ref="personChineseName">{{item.userName}}</div>
                    <div class="personLoginName">{{item.loginName}}</div>
                </div>
                <van-checkbox
                        v-model="item.checked"
                        shape="square"
                        checkedColor="#85B214"
                        ref="checkboxes"
                ></van-checkbox>
            </div>
        </van-list>
        <div class="personListBottom">
            <van-button round block type="info" native-type="submit" @click="SelectedPerson">
                {{btnText}}
            </van-button>
        </div>
    </div>
</template>

<script>
import defaultPhoto from '@/assets/images/defaultPhoto.png'
export default {
    name: "PersonList",
    props: {
        loading:Boolean,
        finished:Boolean,
        list: Array,
        choosePerson:Array,
        btnText: String
    },
    data () {
        return {
            imgUrl: defaultPhoto,
            checkedPerson: this.$props.choosePerson,
            checkedList: this.$props.list
        }
    },
    methods: {
        onLoad(){
            this.$emit("load")
        },
        toggleCheck(index){
            this.$refs.checkboxes[index].toggle();
        },
        SelectedPerson(){
            this.checkedPerson=[];
            this.checkedList.forEach(item => {
                if(item.checked){
                    this.checkedPerson.push(item)
                }
            });
            this.$emit("submitSelected",this.checkedPerson)
        }
    },
}
</script>

<style scoped>

</style>
