<template>
    <van-field :class="item.class" v-model="item.value" :type="item.formType=='mobile'?'tel':''"
        :placeholder="item.placeholder" :disabled="item.disabled" :required="required" maxlength="50"
        :rules="[{ validator, message:'请填写'+item.name }]">
        <template #label>
            <span class="custom-title">{{label}}
                <van-icon v-if="help" @click="showHelp" name="question" /></span>
        </template>
    </van-field>
</template>

<script>
    import QaBase from "./QaBase";

    export default {
        extends: QaBase,
        name: "QaText",
        mounted() {

        },
        methods: {
            validator(val, rule) {
                var flag = true;
                if (this.required) {
                    flag = val.length > 0 ? true : false;
                }
                if (this.item.formType == "mobile") {
                    // rule.message = "必须为11位手机号格式";
                    // flag = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
                } else if (this.item.formType == "floatnumber" && val.length > 0) {
                    rule.message = "必须为正整数不能超过10位";
                    flag = /^[1-9]\d{0,10}$/.test(val);
                }
                return flag;
            },
            validator2(val) {}
        }
    }
</script>