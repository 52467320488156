import axios from 'axios'
import {Dialog} from 'vant'

const service = axios.create({
    timeout: 50000 // request timeout
})

service.interceptors.request.use(
    config => {

    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.retCode !== '000000') {
            //如果为900000，说明token已经过期（或者它处登录更改了token）
            if (res.retCode == '900000') {
                Dialog.confirm({
                    title: '认证过期',
                    message: '认证已经过期，请关闭应用后从企业微信中重新进入！',
                })
                    .then(() => {
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            return Promise.reject(new Error(res.retMsg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        alert("error")
        return Promise.reject(error)
    }
)

export default service
