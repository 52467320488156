<template>
    <van-field :class="item.class"
            v-model="item.value"
            :placeholder="item.placeholder"
            rows="2"
            autosize
            type="textarea"
            :disabled="item.disabled"
            :required="required"
            maxlength="999"
            >
        <template #label>
            <span  class="custom-title">{{label}}<van-icon v-if="help" @click="showHelp"   name="question" /></span>
        </template>
    </van-field>
</template>

<script>
    import QaBase from "./QaBase";

    export default {
        extends: QaBase,
        name: "QaTextArea",
        mounted() {

        }
    }
</script>
