<template>
  <div>
    <van-field
      v-model="item.value"
      :class="item.class"
      :placeholder="item.placeholder"
      :disabled="item.disabled"
      :required="required"
      :rules="[{ validator, message:'请填写'+item.name }]"
      maxlength="50"
    >
      <template #label>
        <span class="custom-title">
          {{ label }}
          <van-icon v-if="help" @click="showHelp" name="question" />
        </span>
      </template>
    </van-field>
    <div class="tag-wrap">
      <div
        v-for="(tag, index) in item.tags"
        :key="index"
        :class="{ active: tag.value == item.value }"
        @click="item.value = tag.value"
      >
        {{ tag.key }}
      </div>
    </div>
  </div>
</template>

<script>
import QaBase from "./QaBase";

export default {
  extends: QaBase,
  name: "QaAutopromptingText",
  computed: {
    tags() {
      return this.item.tags.map(item => {
        if (typeof item) {
          return { key: item, value: item }
        } else {
          return item
        }
      })
    }
  },
  methods: {
    validator(val, rule) {
      var flag = true;
      if (this.required) {
          flag = val.length > 0 ? true : false;
      }
      if (this.item.formType.includes('mobile') ) {
          // rule.message = "必须为11位手机号格式";
          // flag = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
      } else if (this.item.formType.includes('floatnumber') && val.length > 0) {
          rule.message = "必须为正整数不能超过10位";
          flag = /^[1-9]\d{0,10}$/.test(val);
      }
      return flag;
    },
  }
};
</script>

<style lang="less" scoped>
.van-field {
  padding-bottom: 0;
  &::after {
    display: none;
  }
}
.tag-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px 10px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    width: 20%;
    margin-right: 15px;
    height: 24px;
    border-radius: 5px;
    font-size: 12px;

    &.active {
      background-color: #0044e4;
      color: #fff;
    }
  }

  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0.5rem;
    bottom: 0;
    left: 0.5rem;
    border-bottom: 0.03125rem solid var(--separatorColor);
    transform: scaleY(0.5);
  }
}
</style>
