<template>
    <div :class="'van-after-border '+item.class">
        <van-field readonly clickable :name="item.key" v-model="item.value" :placeholder="item.placeholder||'点击选择日期'"
            @click="_click" is-link>
            <template #label>
                <span class="custom-title">{{label}}
                    <van-icon v-if="help" @click="showHelp" name="question" /></span>
            </template>
        </van-field>
        <van-popup position="bottom" v-model="showPicker">
            <van-datetime-picker type="date" @cancel="onCancel" @confirm="onConfirm"  v-model="currentDate" />
        </van-popup>
    </div>
</template>
<script>
    import QaBase from "./QaBase";
    import {
        parseTime
    } from "../../../utils";
    export default {
        name: "QaDate",
        extends: QaBase,
        data() {
            return {
                refProp: this.$props.item,
                showPicker: false,
                currentDate:new Date()
            }
        },
        props: ["item"],
        mounted() {
            this.item.value = this.item.value.length?this.item.value.split(" ")[0]:"";
        },
        computed: {
            columns: function () {
                let columns = this.initValue
                return columns.map(function (option) {
                    return option.label
                })
            }
        },
        methods: {
            _click() {
                if (this.disabled || this.readonly) {
                    this.showReadonlyTip();
                    return;
                }
                this.showPicker = true
            },
            onConfirm(text) {
                this.showPicker = false;
                this.$props.item.value = parseTime(text, '{y}-{m}-{d}')
                this.$props.item.showVal = parseTime(text, '{y}-{m}-{d}')
            },
            onCancel() {
                this.showPicker = false;
            }
        }
    }
</script>