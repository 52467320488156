<template>
    <div :class="'van-after-border ' + item.class">
        <van-field :readonly="true" clickable :value="item.value" :disabled="item.disabled"
            @click="_click" :placeholder="item.placeholder || ''" is-link>
            <template #label>
                <span class="custom-title">{{label}}
                    <van-icon v-if="help" @click="showHelp" name="question" /></span>
            </template>
        </van-field>
        <div>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker :default-index="selectedIndex" show-toolbar :columns="columns" @confirm="onConfirm"
                    @cancel="onCancel" />
            </van-popup>
        </div>
    </div>
</template>
<script>
    //支持t v 或者 label key
    import QaBase from "./QaBase";
    import request from '@/utils/request'
    import {
        Toast
    } from 'vant'
    import {
        toJson
    } from "@/utils/d-form";

    export default {
        name: "QaSelect",
        extends: QaBase,
        data() {
            return {
                refProp: this.$props.item,
                selectedIndex: 0,
                showPicker: false,
                showVal: "",
                columns: []
            }
        },
        mounted() {
            let $this = this;
            if (this.initValue && typeof this.initValue == 'string') {
                let url = this.URLS.API_BASE_URL + "proxy" + this.initValue;
                this.initValue = [];
                request({
                    url: url
                }).then(response => {
                    response.data.data.forEach((val, index) => {
                        let item = {};
                        item.label = val.statusName;
                        item.key = val.id
                        item.options = val
                        this.initValue.push(item)
                        this.columns.push(item.label || item.t);
                        if (val.id == $this.$props.item.value) {
                            $this.selectedIndex = index;
                            $this.$props.item.showVal = item.label;
                            $this.showVal = item.label
                        }
                    })
                })
            } else {
                this.initValue.forEach((item, index) => {
                    this.columns.push(item.label || item.t)
                    if ($this.$props.item.value ? false : true) {
                        if (item.s) {
                            $this.selectedIndex = index;
                            $this.$props.item.value = item.v;
                            $this.$props.item.showVal = item.t;
                            $this.showVal = item.t
                        }
                    } else {
                        if (item.v == $this.$props.item.value) {
                            $this.selectedIndex = index;
                            $this.$props.item.showVal = item.t;
                            $this.showVal = item.t
                        }

                    }
                })
            }
        },
        props: ["item"],
        methods: {
            _click() {
                if (this.$props.item.disabled || this.$props.item.readonly) {
                    Toast.fail("此为只读项，不可修改！")
                } else {
                    this.showPicker = !this.showPicker
                }
            },
            onConfirm(value, index) {
                if (this.$props.item.disabled || this.$props.item.readonly) {
                    //alert("此为不可编辑啊项！")
                    //return;
                }
                this.showPicker = false;
                this.$props.item.value = this.initValue[index].key || this.initValue[index].v
                this.$props.item.showVal = value;
            },
            onCancel() {
                this.showPicker = false;
            }
        }
    }
</script>